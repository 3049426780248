import React from "react";

const PricingInfo = () => {
  const prices = [
    { label: "Pack 1 nuit LPD + diner gala", price: "210 TND / Personne" },
    { label: "Pack 2 nuits LPD + diner gala", price: "260 TND / Personne" },
    { label: "Pack 2 nuits DP + diner gala", price: "310 TND / Personne" },
    { label: "Pack 2 nuits PC + diner gala", price: "330 TND / Personne" },
    { label: "Pack 3 nuits LPD + diner gala", price: "320 TND / Personne" },
    { label: "Pack 3 nuits DP + diner gala", price: "380 TND / Personne" },
    { label: "Pack 3 nuits PC + diner gala", price: "420 TND / Personne" },
    {
      label: "Diner gala simple (sans hébérgement)",
      price: "160 TND / Personne, enfant de 4 à 11 ans 100DT",
    },
  ];

  const additionalInfo = [
    "Supplément single: 40DT / jour",
    "Info and Resa Line: +216 29 333 620 / +216 29 333 613",
  ];

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <img
            src="/assets/img/saint-sylvestre.jpg"
            alt=""
            width="100%"
            height="auto"
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="card shadow">
            <div className="card-header bg-primary text-white">
              <h4 className="mb-0">Tarifs et Réservations</h4>
            </div>
            <div className="card-body">
              <ul className="list-group mb-4">
                {prices.map((item, index) => (
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span>{item.label}</span>
                    <span className="badge bg-success text-white p-2">
                      {item.price}
                    </span>
                  </li>
                ))}
              </ul>
              <div>
                {additionalInfo.map((info, index) => (
                  <p key={index} className="text-muted mb-2">
                    {info}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingInfo;
